import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import OurProduct from "./components/home/ourProducts";
import NavigationBar from "./components/common/navigation";
import Footer from "./components/common/footer";
import Home from "./components/home/home";
import ContactUs from "./components/contactus/contactus";
import RawMaterial from "./components/rawMartMaterials/rawMaterial";
import Consultation from "./components/consultation/consultation";
import OurService from "./components/ourServices/ourServices";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import TMAC from "./components/sme/TenderManagementConsulting";
import ProcurementServices from "./components/sme/procurementServices";
import RawmaterialFinancingSupplyChainFinancing from "./components/sme/RawmaterialFinancingSupplyChainFinancing";
import ProfilingAndBusinessStrategy from "./components/sme/profilingAndBusinessStrategy";
import "./App.css";
import Involvement from "./components/involvement/involvement";
import Solutions from "./components/solutions/solutions";
import Evolvement from "./components/envolvement/evolvement";
function App(props) {
	const { innerWidth } = window;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	let [hideSubFooter, setSubFooter] = useState(false);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	//let [url, setUrl] = useState(window.location.href.split("/").pop());

	// eslint-disable-next-line react-hooks/rules-of-hooks
	// let location = useLocation();

	return (
		<div className="wrapper">
			<Router>
				<div className="">
				{/* <TitleUpdater/> */}
					<NavigationBar></NavigationBar>
					<ToastContainer
						position="top-right"
						autoClose={3000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
				<div
					className="socialMedia"
					style={{ display: innerWidth <= 991 ? "none" : "initial" }}
				>
					<div className="header-socials socialMediaList">
						<a
							href="https://www.instagram.com/rawmart_material_solutions/"
							target="_blank"
							className=""
						>
							<i className="fa-brands fa-instagram"></i>
						</a>
						<a href="#">
							{/* <i className="fa-brands fa-twitter"></i> */}
							<i class="fa-brands fa-x-twitter"></i>
						</a>
						<a href="#">
							<i className="fa-brands fa-google-plus-g"></i>
						</a>
						<a
							href="https://www.linkedin.com/company/rawmart/"
							target="_blank"
						>
							<i className="fa-brands fa-linkedin-in"></i>
						</a>
					</div>
				</div>
				<Routes>
					<React.Fragment>
						<Route
							path="/"
							exact
							element={<Home setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/contactus"
							element={<ContactUs setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/services"
							element={<OurService setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/rawmaterial"
							element={<RawMaterial setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/consultation"
							element={<Consultation setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/privacypolicy"
							element={<PrivacyPolicy setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/products"
							element={<OurProduct setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/Invovle"
							element={<Involvement setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/Solve"
							element={<Solutions setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/Evolve"
							element={<Evolvement setSubFooter={setSubFooter} />}
						/>
						
						<Route
							path="/TenderManagementConsulting"
							element={<TMAC setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/procurementConsulting"
							element={<ProcurementServices setSubFooter={setSubFooter} />}
						/>
						<Route
							path="/profilingAndBusinessStrategy"
							element={
								<ProfilingAndBusinessStrategy setSubFooter={setSubFooter} />
							}
						/>
						<Route
							path="/RawmaterialFinancingSupplyChainFinancing"
							element={
								<RawmaterialFinancingSupplyChainFinancing
									setSubFooter={setSubFooter}
								/>
							}
						/>
					</React.Fragment>
				</Routes>
				<div className="footer">
					<Footer setSubFooter={hideSubFooter}></Footer>
				</div>
			</Router>
		</div>
	);
}

export default App;

// export const TitleUpdater = () => {
// 	const location = useLocation();
  
// 	useEffect(() => {
// 	  const { pathname } = location;
// 	  const pathSegments = pathname.split('/').filter((segment) => segment !== ''); // Split path segments
  
// 	  // Create a dynamic title based on the current path segments
// 	  const title = `Your Title - ${pathSegments.join(' > ')}`;
  
// 	  // Update the title using react-helmet
// 	  const helmet = Helmet.peek();
// 	  helmet.title = title;
// 	}, [location]);
  
// 	return null;
//   };
  
