import React, { useState } from "react";
import Info from "../home/info";
import ContactForm from "../common/contacrForm";
import { useEffect } from "react";
import "./style.css"
import { useLocation } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import MetaTags from 'react-meta-tags';

import { Helmet } from 'react-helmet';


export default function Involvement(props) {

const meta_tag ='Invovle';
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Involvement";
        const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute('content', meta_tag);
    }
 
//         document.head.innerHTML+=`
//   <meta name='description' content='My description value!!!'/>
//   <meta name='keywords' content='My keywords!!!'/>
//   `
        const currentUrl = window.location.href;
        console.log(currentUrl)
        const metaTags = Array.from(document.getElementsByTagName('meta'));

        const ogUrlMeta = metaTags.find((meta) =>
            meta.getAttribute('property') === 'og:site_name'
            );
            console.log("urllll",ogUrlMeta);

        if (ogUrlMeta) {

          ogUrlMeta.setAttribute("content",`${window.location.href}`);
        } else {
            const newOgUrlMeta = document.createElement('meta');
            newOgUrlMeta.setAttribute('property', 'og:site_name');
            newOgUrlMeta.content = currentUrl;
            document.head.appendChild(newOgUrlMeta);
        }
    }, [meta_tag]);
        // const dynamicKeywords = 'Invovle'; // Provide the dynamic keywords here

        // const metaKeywords = document.querySelector('meta[name="keywords"]');
        // if (metaKeywords) {
        //     metaKeywords.setAttribute('content', "Invovle");
        // } else {
        //     const newMetaKeywords = document.createElement('meta');
        //     newMetaKeywords.setAttribute('name', 'keywords');
        //     newMetaKeywords.setAttribute('content', dynamicKeywords);
        //     document.head.appendChild(newMetaKeywords);
        // }

    let [activeClass, setActiveClass] = useState("aerospace");

    const CommonClass = "tab-pane hide";
    let data = {

        tmtRebears: {
            last: true,
            products: [
                {
                    productName: "Gujarat:",
                    para: [
                        `Gujarat has been focusing on infrastructure development in areas such as ports, roads, and industrial corridors.
                        `,
                        `Notable projects in Gujarat include the dedicated freight corridor, the Delhi-Mumbai Industrial Corridor, and the development of ports like Kandla, Mundra, and Pipavav.
                        `,
                        `The state has also invested in the development of metro rail projects in cities like Ahmedabad and Surat.
                        `,
                        `In Gujarat, we are available in all Gujarat Industrial Development Corporation (GIDC) estates across the state, including Vapi, Ankleshwar, Ahmedabad, Surat, and Vadodara. Count on us to streamline your operations and provide access to the raw materials and supplies you need.
                        `
                    ],
                },
                {
                    productName: "Maharashtra:",
                    para: [
                        `Maharashtra is known for its extensive infrastructure projects, particularly in Mumbai and Pune.
                        `,
                        `Major projects in the state include the Mumbai Metro, Mumbai Trans Harbour Link, Mumbai-Nagpur Expressway, and the development of various industrial corridors.
                        `,
                        `The Mumbai-Ahmedabad High-Speed Rail (Bullet Train) project is also a significant infrastructure initiative in Maharashtra.
                        `,
                        `Maharashtra is another region where we have a strong presence. We are available to serve in all Maharashtra Industrial Development Corporation (MIDC) estates, covering areas such as Mumbai, Pune, Nashik, Aurangabad, and Nagpur. As your reliable partner, we offer a streamlined procurement process and access to a wide range of products.`
                    ],
                },
                {
                    productName: "Goa:",
                    para: [
                        `Goa's infrastructure projects primarily focus on improving connectivity, tourism, and urban development.
                        `,
                        `Some ongoing projects include the expansion and modernization of the Goa International Airport, road development, and the construction of bridges.
                        `,
                        `In Goa, we are available in all  the industrial estates including Verna Industrial Estate, Kundaim Industrial Estate, Pilerne Industrial Estate, and Tuem Industrial Estate. We ensuring smooth procurement and providing value-added services to enhance efficiency and productivity.                        `
                    ],

                },
                {
                    productName: "Karnataka:",
                    para: [
                        `Karnataka has a robust infrastructure sector, with projects spanning multiple areas.
                        `,
                        `Bengaluru Metro is one of the most prominent projects in the state, aiming to improve urban transportation.
                        `,
                        `Other initiatives include the development of industrial corridors, upgrading of airports, and improvements in road connectivity.
                        `,
                        `Karnataka is one of our key focus areas. `,
                        `We are available to serve the industrial areas, including the Peenya Industrial Area in Bengaluru, Bidadi Industrial Area, Hosur Industrial Area, Belagavi Industrial Area, and Mysuru Industrial Area. With our digital platform, you can source materials, streamline processes, and access a diverse range of suppliers easily.
                        `
                    ],


                },
                {
                    productName: "Madhya Pradesh:",
                    para: [
                        `Madhya Pradesh has been actively working on infrastructure development to boost economic growth.
                        `,
                        `Key projects include the Delhi-Mumbai Industrial Corridor, development of smart cities like Bhopal and Indore, and expansion of road and rail networks.
                        `,
                        `The state also aims to enhance tourism infrastructure and improve power transmission facilities.
                        `,
                        `Rawmart is committed to supporting the growth of businesses in Madhya Pradesh. We are present in all  industrial areas such as the Pithampur Industrial Area, Mandideep Industrial Area, Bhopal Industrial Area, Indore Industrial Area, and Jabalpur Industrial Area. Leverage our technology-driven solutions to connect with reliable suppliers and streamline your supply chain operations.
                        `
                    ],

                },
                {
                    productName: "Uttar Padesh:",
                    para: [
                        `  Uttar Pradesh (UP) focuses on infrastructure development for economic growth, including the Lucknow Metro, industrial corridors, and smart cities like Lucknow and Varanasi.
                        `,
                        `Expansion of road and rail networks improves connectivity within and beyond UP, promoting trade, commerce, and overall development.
                        `,
                        `Investments in tourism infrastructure and power transmission facilities support sector growth, enhancing the tourist experience in cities like Agra and Varanasi.
                        `,
                        `Rawmart serves industrial areas in UP, such as Noida, Ghaziabad, Kanpur, and Varanasi, offering digital solutions to streamline procurement and connect businesses with reliable suppliers.
                        `
                    ],

                },

            ],
        },
        processes: {
            last: true,
            products: [
                {
                    productName: "Raw Material Procurement:",
                    para: [
                        `Identify reliable suppliers for required raw materials for construction and manufacturing companies. Which is catering to the variable quanitites, grades, variety and geographies.
                        `,
                        `Negotiate pricing and terms with suppliers
                        `,
                        `Place orders and manage the logistics of raw material delivery to the SMEs.
                        `,
                        , `Supply Chain finance :- Giving best financial solutions as per there needs through our partner nbfcs ,banks and other creditors to Push the Bottlenecks created in cash flow , improper credit lines or less availability of credit from existing suppliers.                         `
                    ],
                },
                {
                    productName: "Design Developments",
                    para: [
                        `We work closely with manufacturers to understand their product design requirements. Develop prototypes and iterate based on feedback.  Provide technical expertise and guidance for design improvements.
                        `
                    ],
                },

                {
                    productName: "Process Alignment:",
                    para: [
                        `We analyze the existing manufacturing processes of SMEs.  Identify bottlenecks, inefficiencies, and areas for improvement.  Develop standardized processes and workflow optimizations. Train SME staff on the optimized processes or Put Rawmart martials for micro monitoring the art.
                        `
                    ],


                },
                {
                    productName: "Manufacturing:",
                    para: [
                        ` Execute the manufacturing process based on the aligned procedures.  Ensure quality control measures are in place.   Monitor production efficiency and make adjustments as needed.`,
                        `Conduct quality inspections before delivery team oversees the actual manufacturing process and ensures the final product meets the required quality standards.By integrating these steps and allocating efficient roles to various teams, Rawmart's tech ecosystem can help SMEs overcome challenges and improve their overall operations.
                        `
                    ],

                },

            ],
        },
        aerospace: {
            last: true,
            products: [
                {
                    para: [
                        `By manufacturing and development of aircraft and spacecraft related components. `,
                        ` Rawmart is providing tailored procurement services to source high-quality materials, ensuring compliance with regulations and quality standards. Offering active engineering services to support innovation and customization efforts.                        `
                    ],
                },
            ]
        },
        fmcg: {
            last: true,
            products: [
                {
                    para: [
                        `FMCG Manufacturing and Processing: Fast-Moving Consumer Goods (FMCG) manufacturing, including food, beverages, personal care, and household products. 
                        `,
                        `Rawmart is offering proactive procurement services to source quality metal raw materials, mro items , specialised machines for packaging of materials. We are also providing active engineering services to optimize production processes and improve efficiency. 
                        `

                    ],
                },
            ]
        },
        railways: {
            last: true,
            products: [
                {
                    para: [
                        `Indian Railways is fastest growing sector , the infrastructural projects and New technology rolling stock increase the demand of new products . `,
                        `Rawmart is offering proactive procurement services to source quality materials, products  and material handling equipments to all the sme stake holders of Indian Railways. 
                        `],

                },
            ]
        },
        defense: {
            last: true,
            products: [
                {
                    para: [
                        ` In Production of military equipment, weapons, and defense systems. `,
                        `Rawmart  is actively identifying the industry's need for specialized alloys, composites, electronic components, and engineering solutions. Offering proactive procurement services to source reliable and secure materials. Providing active engineering services for customized solutions and testing/validation.
                        `],
                },
            ]
        },
        infrastructure: {
            last: true,
            products: [
                {
                    para: [
                        `In construction and development of roads, highways, energy projects, ports, irrigation systems, etc.
                        `,
                        `Rawmart is understanding the industry's need for construction materials, equipment, and machinery. Offering proactive procurement services to source quality materials and specialized components. 
                        `],
                },
            ]
        },
        pharmaceutical: {
            last: true,
            products: [
                {
                    para: [
                        `In the processes of production of medicinal drugs, vaccines, and healthcare products.`,
                        `Rawmart is identifying the industry's need for pharmaceutical packaging solution. Offering proactive procurement services to source reliable and compliant materials. Providing active engineering services for process optimization and automation. 
                        `],
                },
            ]
        },
        automotive: {
            last: true,
            products: [
                {
                    para: [
                        `In manufacturing of vehicles, including electric vehicles (EVs) and associated infrastructure. `,
                        `Rawmart is understanding the industry's need for metals, and manufacturing items. Offering proactive procurement services to source high-quality materials and components. Providing active engineering services for EV companies for managing there supply chain and engineering solutions for there assembly parts.
                        `],
                },
            ]
        },
        power: {
            last: true,
            products: [
                {
                    para: [
                        ` In electricity generation from renewable energy, alternative fuels, and traditional sources.`,
                        `Rawmart is identifying the engineered parts need for solar panels, wind turbine parts, batteries, and biofuel production equipment. Offering proactive procurement services to source the required equipment and products.
                        `],
                },
            ]
        }
    };

    function changeProducts(e) {
        setActiveClass(e.target.id);
    }
   
    return (
        <>
    
          <Helmet>
                <meta name="keywords" content={meta_tag} />
                <meta property="og:title"  name="title" content="Involvement" />
           </Helmet>

            <section className="home4 recent-project-section pad100-top projectsec1" style={{ marginTop: "40px" }}>
                <div className="">
                    <div className="row">
                        <div className="container">
                            <h3
                                className="white-color h3Heading heading-h3"
                                style={{ marginBottom: "20px", }}
                            >
                                <span className="clr-blue">SME Verticals </span>
                            </h3>


                        </div>
                        <ul className="nav nav-tabs" role="tablist" onClick={changeProducts}>
                            <li
                                role="presentation"
                                className={activeClass === "aerospace" ? "active" : ""}
                            >
                                <div className="" id="aerospace">
                                    Aerospace
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "fmcg" ? "active" : ""}
                            >
                                <div className="" id="fmcg">
                                    FMCG Manufacturing and Processing
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "railways" ? "active" : ""}
                            >
                                <div className="" id="railways">
                                    Railways
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "defense" ? "active" : ""}
                            >
                                <div className="" id="defense">
                                    Defense
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "infrastructure" ? "active" : ""}
                            >
                                <div className="" id="infrastructure">
                                    Infrastructure
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "pharmaceutical" ? "active" : ""}
                            >
                                <div className="" id="pharmaceutical">
                                    Pharmaceutical
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "automotive" ? "active" : ""}
                            >
                                <div className="" id="automotive">
                                    Automotive
                                </div>
                            </li>
                            <li
                                role="presentation"
                                className={activeClass === "power" ? "active" : ""}
                            >
                                <div className="" id="power">
                                    Power Generation
                                </div>
                            </li>

                        </ul>
                    </div>


                </div>
                <div className="container">

                    <div
                        className={
                            activeClass === "aerospace"
                                ? "show active " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
                            <img src="/assets/images/img/aerospace.jpg" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["aerospace"]} />
                    </div>
                    <div
                        className={
                            activeClass === "fmcg"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
                            <img src="/assets/images/img/fmcg.jpg" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["fmcg"]} />
                        {/* <Info info={data.processes} /> */}
                    </div>
                    <div
                        className={
                            activeClass === "railways"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

                            <img src="/assets/images/img/railway.webp" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["railways"]} />

                    </div>
                    <div
                        className={
                            activeClass === "defense"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

                            <img src="/assets/images/img/defense.jpg" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["defense"]} />

                    </div>
                    <div
                        className={
                            activeClass === "infrastructure"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

                            <img src="/assets/images/img/infrastructure.jpg" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["infrastructure"]} />

                    </div>
                    <div
                        className={
                            activeClass === "pharmaceutical"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

                            <img src="/assets/images/img/pharma.png" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["pharmaceutical"]} />

                    </div>
                    <div
                        className={
                            activeClass === "automotive"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

                            <img src="/assets/images/img/pxfuel_3.jpg" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["automotive"]} />

                    </div>
                    <div
                        className={
                            activeClass === "power"
                                ? "active show " + CommonClass
                                : CommonClass
                        }
                    >
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>

                            <img src="/assets/images/img/power_generation.jpg" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <Info info={data["power"]} />
                    </div>
                </div>
                <section className="geographic" style={{ paddingTop: "40px", backgroundColor: "rgb(235, 233, 233)" }}>
                    <div className="container">
                        <div className="row">
                            <h3
                                className="white-color h3Heading heading-h3"
                                style={{ marginBottom: "0px" }}
                            >
                                <span className="clr-blue">Geographies </span>
                            </h3>
                            <p
                                style={{
                                    margin: "22px 0px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important"
                                }}
                            >
                                Rawmart - Your Procurement Partner in Multiple Geographies
                            </p>
                            <p style={{ fontSize: "16px", marginBottom: "20px" }}>Rawmart is your dedicated procurement partner in multiple geographies. We are consciously present in Gujarat, Maharashtra, Goa, Karnataka, and Madhya Pradesh, ready to support SMEs, manufacturers, and contractors like you.</p>
                            <div>
                                <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
                                    <img src="/assets/images/img/map3.png" style={{ width: "100%", height: "100%" }} />
                                </div>
                                <Info info={data["tmtRebears"]} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="processes_class" style={{ paddingTop: "40px" }}>
                    <div className="container">
                        <div className="row">
                            <h3
                                className="white-color h3Heading heading-h3"
                                style={{ marginBottom: "0px", }}
                            >
                                <span className="clr-blue">Processes</span>
                            </h3>
                            <p
                                style={{
                                    margin: "22px 0px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontFamily: "'Nexa'!important",
                                }}
                            >
                                Rawmart tech ecosystem, along with the efficient role playing in each step to address the problems of SME's:
                            </p>
                            <p style={{ fontSize: "16px", marginBottom: "20px" }}>We get involved at different levels and capacities to restructure the manufacturing for Efficient and scalable manufacturing operations for seamless production.</p>

                            <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", alignItems: "center", margin: "0 auto" }}>
                                <img src="/assets/images/img/processes.jpg" style={{ width: "100%", height: "100%" }} />
                            </div>

                            <Info info={data["processes"]} className="text-white" />

                        </div>
                    </div>
                </section>
                <section className="cutmClass bg-img-contactus">
                    <div className="container">
                        <ContactForm
                            title="Get a Quote"
                            subTitle="Fill up the form and our team will get back to you within 24 hours"
                            productSection={true}
                        />
                    </div>
                </section>
            </section>
        </>
    );
}

