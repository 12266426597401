import React, { useEffect } from "react";

import Slider from "../slider/slider";
import Aboutus from "./aboutUs";
import WhoAreSme from "./whoAreSmes";
import RawmartTechEcosystem from "./rawmartTechEcosystem";
import StatisticSection from "./statisticSection";
import Contactus from "./contactus";
import { Helmet } from 'react-helmet';
import "./style.css";
import WhyRawmart from "./whyRawmart";
import OurProduct from "./ourProducts";
// import OurProduct from "./ourProducts";
export default function Home({ setSubFooter }) {
	const meta_tag ='B2B';

	useEffect(() => {
		window.scrollTo(0, 0);
		document.title = "SME Ecosystem";
		const metaKeywords = document.querySelector('meta[name="keywords"]');
		if (metaKeywords) {
		  metaKeywords.setAttribute('content', meta_tag);
		}
		const currentUrl = window.location.href;

		    const metaTags = Array.from(document.getElementsByTagName('meta'));

		    const ogUrlMeta = metaTags.find((meta) =>
		      meta.getAttribute('property') === 'og:site_name'
		    );

		    if (ogUrlMeta) {
				ogUrlMeta.setAttribute("content",`${window.location.href}`);
		    } else {
		      const newOgUrlMeta = document.createElement('meta');
		      newOgUrlMeta.setAttribute('property', 'og:site_name');
		      newOgUrlMeta.content = currentUrl;
		      document.head.appendChild(newOgUrlMeta);
		    }
		//     const dynamicKeywords = 'B2B'; // Provide the dynamic keywords here

		// const metaKeywords = document.querySelector('meta[name="keywords"]');
		// if (metaKeywords) {
		//   metaKeywords.setAttribute('content', dynamicKeywords);
		// } else {
		//   const newMetaKeywords = document.createElement('meta');
		//   newMetaKeywords.setAttribute('name', 'keywords');
		//   newMetaKeywords.setAttribute('content', dynamicKeywords);
		//   document.head.appendChild(newMetaKeywords);
		// }
		setSubFooter(true);
	}, [meta_tag]);

	const imagesContent = [
		{
			img: "assets/images/slider/slider_1.png",
			path: "/RawmaterialFinancingSupplyChainFinancing",
		},
		{
			img: "assets/images/slider/slider_2.jpg",
			path: "/procurementConsulting",
		},
		{
			img: "assets/images/slider/slider_3.png",
			path: "/profilingAndBusinessStrategy",
		},
		{
			img: "assets/images/slider/slider_4.jpg",
			path: "/profilingAndBusinessStrategy",
		},
	];
	const heading = [
		"Digitized End-to-End Flow",
		"Hyper Connected Procurement Network",
		"Empowering SMEs to Reach Extraordinary Heights",
		"Revolutionizing SME Procurement: Saving, Streamlining, and Elevating Quality"
	];
	return (
		<React.Fragment>
				<Helmet>
			<meta name="keywords" content={meta_tag} />
			<meta property="og:title"  name="title" content="B2B" />

			</Helmet>
    

			<Slider
				overlay={true}
				images={imagesContent}
				heading={heading}
			// subheading={`Empowering businesses to achieve the goals at their best with optimal resources.`}
			/>
			<Aboutus />
			<WhoAreSme />
			<RawmartTechEcosystem />
			<WhyRawmart />
			{/* <OurProcess /> */}
			<StatisticSection />
			{/* <OurProduct /> */}
			<Contactus />
		</React.Fragment>
	);
}
